import React, { useState, useRef } from 'react';
import AppBar from '@material-ui/core/AppBar';
import MatToolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { Link, withRouter } from 'react-router-dom';
import { useContext } from 'react';
import { AdminContext } from '../context/AdminContext';

const useStyles = makeStyles({
	wrapper: {
		display: "grid",
		gridTemplateColumns: "1fr 1fr 1fr",
		width: "100%",
		alignItems: "center",
	},
	menuButton: {
		color: "white",
	},
	live: {
		justifySelf: "right",
		color: "#fff",
	},
	link: {
		color: "inherit",
		textDecoration: "none",
	},
	logo: {
		maxHeight: 48,
		verticalAlign: "middle",
	},
	banner: {
		background: "#FABF66",
		height: 40,
		display: "grid",
		placeItems: "center",
		textDecoration: "none",
		"& h1": {
			color: "white",
			fontSize: 21,
			margin: 0,
			"&:hover": {
				textDecoration: "underline",
			},
		},
	},
});

function Toolbar({ children, history, location }) {
	const classes = useStyles();
	const [menuOpen, setMenuOpen] = useState(false);
	const { user, logout } = useContext(AdminContext);
	const menuAnchorRef = useRef(null);

	return (
		<div style={{ height: '100vh' }}>
			<AppBar position="sticky">
				<MatToolbar>
					<div className={classes.wrapper}>
						<div ref={menuAnchorRef}>
							<Typography variant="h5" className={classes.title}>
								{user ? (
									<IconButton
										className={classes.menuButton}
										onClick={() => setMenuOpen(true)}
									>
										<MenuIcon />
									</IconButton>
								) : null}
							</Typography>
						</div>
						<Typography
							variant="h5"
							style={{ fontWeight: 600 }}
							className={classes.storeName}
							align="center"
						>
							Admin Portal A
						</Typography>
						<Menu
							anchorEl={menuAnchorRef.current}
							keepMounted
							open={menuOpen}
							onClose={() => setMenuOpen(false)}
							onClick={() => setMenuOpen(false)}
						>
							<Link className={classes.link} to="/dashboard">
								<MenuItem>Dashboard</MenuItem>
							</Link>
							<Link className={classes.link} to="/salesreps">
								<MenuItem>Sales Representatives</MenuItem>
							</Link>
							<Link className={classes.link} to="/new-admin">
								<MenuItem>New Admin User</MenuItem>
							</Link>
							{/* <Link className={classes.link} to="/settings">
								<MenuItem>Account Settings</MenuItem>
							</Link> */}
							<MenuItem onClick={async () => logout().then(() => history.push('/login'))}>Logout</MenuItem>
						</Menu>
					</div>
				</MatToolbar>
			</AppBar>
			{children}
		</div>
	);
}

export default withRouter(Toolbar);
