
async function getRecaptchaToken() {
    return new Promise((resolve, reject) => {
        window.grecaptcha.ready(() => {
            window.grecaptcha
                .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY)
                .then(resolve)
                .catch(reject);
        });
    });
}

export { getRecaptchaToken };