import React, { useState } from 'react';
import { useLocation, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardHeader, CardContent, TextField, Button, Typography } from '@material-ui/core';
import CircularProgress from "@material-ui/core/CircularProgress";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableContainer,
    TableRow,
    Paper,
    Dialog,
    DialogTitle
} from "@mui/material";
import ArrowLink from "./ArrowLink";
import { apiService } from "../services/ApiService";
import { getRecaptchaToken } from "../services/RecaptchaService";
import { toast } from "react-toastify";
import { generatePassword } from '../functions';
import MaskedInput from 'react-text-mask';

const useStyles = makeStyles({
    buttonDiv: {
        textAlign: "center",
        padding: "10px",
    },
    qrImg: {
        width: "150px",
    },
    link: {
        color: "#82a98b !important",
        fontSize: "1.2em",
        textDecoration: "none",
        "&:hover": {
            color: "#b4cbb9  !important"
        },
    },
    url: {
        backgroundColor: "#ddd",
        padding: "2px 5px",
        borderRadius: "2px",
        border: "1px solid #888",
    }
});

const NewSalesRep = () => {
    const [loading, setLoading] = useState(false);
    const [rep, setRep] = useState();
    const [open, setOpen] = React.useState(false);
    const [newRep, setNewRep] = useState();

    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const query = new URLSearchParams(location.search);
    const page = query.get("page");

    const onSubmit = async (event) => {
        event.preventDefault();
        try {
            setLoading(true);
            let token = await getRecaptchaToken();
            let formData = rep;
            const exists = await apiService.post('account/user-exists', {
                email: rep.email,
                role: 'salesrep',
                recaptchaToken: token
            });
            
            if (exists === 'yes') {
                toast.error('Email is already registered');
                setLoading(false);
            }
            else {
                let endpoint = 'adminsales/signup';                
                if (exists === 'other') {
                    endpoint += '/sales-only'
                }
                else {
                    formData['password'] = generatePassword(16);
                }
                formData['recaptchaToken'] = await getRecaptchaToken();
                console.log(formData)
                apiService
                    .post(endpoint, formData)
                    //.then(res => res.json())
                    .then(res => {
                        console.log(res);
                        setOpen(true);
                        setNewRep({ ...res });
                    })
                    .then(() => setLoading(false))
                    .catch(e => {
                        toast.error(e);
                        setLoading(false);
                    });
            }
        } catch (err) {
            console.log(err)
            toast.error(err);
        }
    }

    const handleClose = () => {
        setOpen(false);
        history.push("/salesreps");
    }

    const copyToClipboard = (e) => {
        navigator.clipboard.writeText(e.target.innerHTML);
        var tooltip = document.getElementById("tooltiptext");
        tooltip.innerHTML = "Copied!";
    }

    const tooltipTextChange = () => {
        var tooltip = document.getElementById("tooltiptext");
        tooltip.innerHTML = "Copy to clipboard";
    }

    return (
        <>
            <Card elevation={0}>
                <CardHeader></CardHeader>
                <CardContent>
                    <form id="salesForm" onSubmit={onSubmit}>
                        <Paper elevation={2}>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell colSpan={5} align="center">
                                                <ArrowLink path='/salesreps' state={{ page: page }} styles={{ color: "#fff" }} />
                                                <Typography component={"span"} style={{ fontWeight: 600 }}>New Sales Representative</Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Phone Number</TableCell>
                                            <TableCell>Email</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <TextField variant="outlined" id="name" required
                                                    onChange={(e) => setRep({ ...rep, name: e.target.value })} />
                                            </TableCell>
                                            <TableCell>
                                                <TextField variant="outlined" id="phoneNumber" required
                                                    onChange={(e) => setRep({ ...rep, phoneNumber: e.target.value })}
                                                    InputProps={{
                                                        inputComponent: MaskedInput,
                                                        inputProps: {
                                                            mask: ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
                                                        },
                                                    }} />
                                            </TableCell>
                                            <TableCell>
                                                <TextField variant="outlined" id="email" required
                                                    onChange={(e) => setRep({ ...rep, email: e.target.value })} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <div className={classes.buttonDiv}>
                                <Button
                                    type="submit"
                                    disabled={loading}
                                    variant="contained"
                                    color="primary"
                                    style={{ minWidth: "200px" }}
                                >
                                    Save 
                                    {loading && <CircularProgress size={24} />}
                                </Button>
                            </div>
                        </Paper>
                    </form>
                </CardContent>
            </Card>
            <Dialog open={open} onClose={handleClose} PaperProps={{ sx: { padding: "15px" } }}>
                <DialogTitle sx={{ fontWeight: "600" }}>Sales representatives created successfully</DialogTitle>
                <div style={{ textAlign: "center", paddingBottom: "16px", }}>
                    <img src={newRep?.qr} className={classes.qrImg} alt="QR Code" />
                    <br />
                    <a
                        className={classes.link}
                        download={newRep?.name + '_qrcode.dib'}
                        href={newRep?.qr}>
                        Download
                    </a>
                    <br />
                    <span onClick={copyToClipboard} onMouseOut={tooltipTextChange} className='tooltip'>
                        <span id="tooltiptext" className="tooltiptext">Copy to clipboard</span>
                        <code className={classes.url}>{newRep?.url}</code>
                    </span>
                </div>
            </Dialog>
        </>
    );
}

export default NewSalesRep;