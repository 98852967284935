import React from "react";
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const ArrowLink = ({ path, state, styles }) => {
        return (
            <Link to={{ pathname: path, state: state }} style={{ float: "left" }}>
                <ArrowBackIcon sx={{ verticalAlign: "text-top", ...styles }} fontSize="large"></ArrowBackIcon>
            </Link>
        );
}

export default ArrowLink;