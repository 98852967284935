import React, { useEffect, useState } from "react";
import DataTable from "./DataTable";
import { Card, CardHeader, CardContent } from '@material-ui/core';
import { useParams, useLocation } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import ArrowLink from './ArrowLink';
import { apiService } from "../services/ApiService";
import { toast } from "react-toastify";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles({
    loaderWrapper: {
        height: "var(--fullvh)",
        display: "grid",
        justifyContent: "center",
        alignItems: "center",
    },
    header: {
        paddingBottom: "0 ! important",
    },
});

const columnSettings = [
    {
        id: 'started',
        label: 'Started',
        data: 'dateTimeStarted',
        type: 'date',
        sort: true,
    },
    {
        id: 'ended',
        label: 'Ended',
        data: 'dateTimeEnded',
        type: 'date',
        sort: true,
    },
    {
        id: 'duration',
        label: 'Duration',
        data: 'duration',
        type: 'numeric',
        format: (seconds) => {
            let min = Math.floor(seconds / 60);
            let sec = Math.round(seconds % 60);
            return min + ' min ' + sec + ' sec';
        },
        sort: true,
    },
    {
        id: 'responded',
        label: 'Responded',
        data: 'responded',
        type: 'boolean',
        sort: true,
    },
    {
        id: 'rating',
        label: 'Rating',
        data: 'storeRatings',
        type: 'numeric',
        format: (rating) => {
            let returnStr = '';
            if (rating) {
                return [...Array(rating)].map(() => <span>&#9733;</span>);
            }
            return returnStr;
        },
        sort: true,
    },
    {
        id: 'feedback',
        label: 'Feedback',
        data: 'feedback',
        sort: true,
    },
    {
        id: 'endedAs',
        label: 'Ended As',
        data: 'endedAs',
        sort: true,
    }
]

const BellReport = () => {
    const [title, setTitle] = useState();
    const [isLoading, setIsLoading] = useState(false);

    let { id } = useParams();
    const location = useLocation();
    const classes = useStyles();

    const getStore = () => {
        apiService
            .get(`adminstore/storeReports/${id}`)
            .then(res => {
                setTitle(res[0].name);
            })
            .catch((e) => {
                setIsLoading(false);
                toast.error(e);
            });
    }

    useEffect(() => getStore(), []);

    console.log(location.state)
    return (isLoading ?
        <div className={classes.loaderWrapper}>
            <CircularProgress />
        </div>
        : <Card elevation={0}>
            <CardHeader title={title} className={classes.header}
                avatar={<ArrowLink path={location.state.from} state={location.state} styles={{ color: "black" }} />}
                titleTypographyProps={{ variant: "h5", align: "center" }} />
            <CardContent>
                <DataTable
                    dataURL={`adminStore/pingReport/${id}`}
                    columnSettings={columnSettings} />
            </CardContent>
        </Card >
    );
}

export default BellReport;
