import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardHeader, CardContent, Button } from '@material-ui/core';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  Paper,
  TableSortLabel,
  TablePagination,
  Box,
} from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import { apiService } from "../services/ApiService";
import { visuallyHidden } from "@mui/utils";
import { getComparator } from "../functions";

const useStyles = makeStyles({
  card: {
    boxShadow: "1px 2px 25px 5px rgb(0 0 0 / 20%) !important",
  },
  header: {
    textAlign: "center",
  },
  loaderWrapper: {
    height: "var(--fullvh)",
    display: "grid",
    justifyContent: "center",
    alignItems: "center",
  },
  logo: {
    width: "35px",
  },
  inactive: {
    backgroundColor: "rgba(0, 0, 0, 0.04)",
  },
});

const columnSettings = [
  {
    id: "name",
    numeric: false,
    label: "Store Name",
    colSpan: 2,
    data: "name",
  },
  {
    id: "plan",
    numeric: false,
    label: "Plan",
    data: "plan.type",
  },
  {
    id: "phone",
    numeric: false,
    label: "Phone Number",
    data: "phone",
  },
  {
    id: "email",
    numeric: false,
    label: "Email",
    data: "email",
  },
  {
    id: "pingsCount",
    numeric: true,
    label: "Pings",
    data: "pingsCount",
  },
  {
    id: "signupRef",
    numeric: false,
    label: "Referred By",
    data: "signupRef",
  },
];

const StoreTable = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [loadData, setLoadData] = useState(true);
  const [data, setData] = useState(null);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();

  const getTableData = () => {
    setIsLoading(true);
    apiService
      .get("adminstore/storeReports")
      .then((res) => {
        setData(res);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        toast.error(e);
      });
  };

  const deactivateStore = async (e, id) => {
    e.stopPropagation();
    let active = data.find((s) => s.storeId === id).isActive;
    const prefix = active ? "de" : "";
    if (window.confirm("Are you sure you want to " + prefix + "activate?")) {
      apiService
        .put(`adminstore/${prefix}activate/${id}`)
        .then(() => setLoadData(true))
        .catch((e) => {
          toast.error(e.error ?? e)
          setLoadData(true);
        });
    }
  };

  useEffect(() => {
    if (loadData) {
      getTableData();
      if (location.state && location.state.page) setPage(location.state.page);
      setLoadData(false);
    }
  }, [loadData, location.state]);

  const handleRowClick = (id) => {
    let store = data.find((s) => s.storeId === id);
    //let active = store.isActive;
    //if (active) 
    history.push(`/store/${id}`, { from: location, store: store, page: page });
  };

  const handleSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  return (
    <>
      {isLoading ? (
        <div className={classes.loaderWrapper}>
          <CircularProgress />
        </div>
      ) : (
        <Card elevation={0}>
          <CardHeader></CardHeader>
          <CardContent>
            <TableContainer component={Paper} elevation={2}>
              <TableContainer sx={{ overflowY: 'auto', height: '81vh' }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      {columnSettings.map((col) => (
                        <TableCell
                          key={col.id}
                          align={col.numeric ? "right" : "left"}
                          sortDirection={orderBy === col.id ? order : false}
                          colSpan={col.colSpan ?? 1}
                        >
                          <TableSortLabel
                            active={orderBy === col.id}
                            direction={orderBy === col.id ? order : "asc"}
                            onClick={(e) => handleSort(e, col.id)}
                          >
                            {col.label}
                            {orderBy === col.id ? (
                              <Box component="span" sx={visuallyHidden}>
                                {order === "desc"
                                  ? "sorted descending"
                                  : "sorted ascending"}
                              </Box>
                            ) : null}
                          </TableSortLabel>
                        </TableCell>
                      ))}
                      <TableCell className={classes.headerCell}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => history.push("/new-store")}
                        >
                          New
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data
                      .sort(getComparator(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((store, index) => {
                        return (
                          <TableRow
                            hover={store.isActive}
                            onClick={() => handleRowClick(store.storeId)}
                            className={store.isActive ? "" : classes.inactive}
                            key={index}
                          >
                            <TableCell>
                              <img src={store.logo} className={classes.logo} />
                            </TableCell>
                            <TableCell>{store.name}</TableCell>
                            <TableCell>{store.plan.type.toUpperCase()}</TableCell>
                            <TableCell>{store.phone}</TableCell>
                            <TableCell>{store.email}</TableCell>
                            <TableCell align="right">{store.pingsCount}</TableCell>
                            <TableCell>{store.signupRef}</TableCell>
                            <TableCell>
                              <Button
                                onClick={(e) => deactivateStore(e, store.storeId)}
                                color="primary"
                              >
                                {store.isActive ? "Deactivate" : "Activate"}
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>

              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(event, newPage) => setPage(newPage)}
                onRowsPerPageChange={handleChangeRowsPerPage}
                showFirstButton={true}
                showLastButton={true}
              />
            </TableContainer>
          </CardContent>
        </Card>
      )}
    </>
  );
};

export default StoreTable;
