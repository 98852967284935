import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

import { apiService } from '../services/ApiService';
import { toast } from 'react-toastify';

const styles = {
	root: {
		height: '100%',
	},
	logo: {
		height: '100px',
		margin: '0 auto 20px auto',
	},
	form: {
		height: '100%',
		alignContent: 'center',
		display: 'grid',
		rowGap: '30px',
		margin: '0 auto',
		maxWidth: '400px',
	},
	loaderWrapper: {
		height: 'var(--fullvh)',
		display: 'grid',
		justifyContent: 'center',
		alignItems: 'center',
	},
	readonly: {
		'& input': {
			opacity: .5,
			cursor: 'default',
			pointerEvents: 'none',
		},
		'& label': {
			opacity: .8,
		},
		'& :hover .MuiOutlinedInput-notchedOutline': {
			borderColor: 'rgba(0, 0, 0, 0.23)',
		}
	}
};

class ResetPasswordWithToken extends React.Component {
	state = {
		isLoading: false,
	};

	urlParams = new URLSearchParams(window.location.search);
	email = this.urlParams.get('email');

	onSubmit = async (e) => {
		try {
			e.preventDefault();
			this.setState({ isLoading: true });
			let loginInfo = this.formToObject(e.target);
			loginInfo.resetToken = this.getResetToken();
			loginInfo.recaptchaToken = await this.getRecaptchaToken();
			console.log(loginInfo);
			await apiService.post(`account/reset-password-with-token`, loginInfo);
			toast.success('Password reset successful, please login now');
			this.props.history.push(`/login`);
		} catch (err) {
			this.setState({ isLoading: false });
			console.error(err);
		}
	};

	formToObject(form) {
		return Object.fromEntries(new FormData(form));
	}

	getResetToken() {
		return this.urlParams.get('resetToken');
	}

	async getRecaptchaToken() {
		return new Promise((resolve, reject) => {
			window.grecaptcha.ready(() => {
				window.grecaptcha
					.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY)
					.then(resolve);
			});
		});
	}

	renderForm(classes) {
		return (
			<>
				<style>{`
					.grecaptcha-badge {
						visibility: visible;
					}
				`}</style>
				<form className={classes.form} onSubmit={this.onSubmit}>
					<img className={classes.logo} src="/curbsideBell-logo.png" alt="" />
					<TextField
						name="email"
						required
						type="email"
						label="Email"
						variant="outlined"
						value={this.email}
						className={this.email ? classes.readonly : ''}
						onFocus={(e) => { if (this.email) e.target.blur() }}
					/>
					<TextField
						name="password"
						required
						label="New Password"
						variant="outlined"
						type="password"
						InputProps={{ autoComplete: 'new-password' }}
					/>
					<Button type="submit" variant="contained" color="primary">
						Reset Password
					</Button>
				</form>
			</>
		);
	}

	renderLoader(classes) {
		return (
			<div className={classes.loaderWrapper}>
				<CircularProgress />
			</div>
		);
	}

	render() {
		const classes = this.props.classes;
		return (
			<div className={classes.root}>
				{this.state.isLoading
					? this.renderLoader(classes)
					: this.renderForm(classes)}
			</div>
		);
	}
}

export default withStyles(styles)(ResetPasswordWithToken);
