import React, { useState } from 'react';
import { useLocation, useHistory, Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardHeader, CardContent, TextField, Select, MenuItem, Button, Typography } from '@material-ui/core';
import CircularProgress from "@material-ui/core/CircularProgress";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableContainer,
    TableRow,
    Paper,
} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { apiService } from "../services/ApiService";
import { getRecaptchaToken } from "../services/RecaptchaService";
import { toast } from "react-toastify";
import { generatePassword } from '../functions';
import MaskedInput from 'react-text-mask';

const useStyles = makeStyles({
    buttonDiv: {
        textAlign: "center",
        padding: "10px",
    }
});

const NewStore = () => {
    const [loading, setLoading] = useState(false);
    const [store, setStore] = useState({ plan: 'basic' });

    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const query = new URLSearchParams(location.search);
    const page = query.get("page");

    const onSubmit = async (event) => {
        event.preventDefault();
        try {
            setLoading(true);
            let formData = store;
            formData["password"] = generatePassword(16);
            formData["recaptchaToken"] = await getRecaptchaToken();
            console.log(formData)
            apiService
                .post("account/signup/store", formData)
                .then(() => {
                    toast.success('Store created successfully!');
                    history.push('/dashboard');
                })
                .then(() => setLoading(false))
                .catch(e => {
                    console.log(e)
                    toast.error(e);
                    setLoading(false);
                });            
        } catch (err) {
            console.log(err)
            toast.error(err);
        }
    }

    return (
        <Card elevation={0}>
            <CardHeader></CardHeader>
            <CardContent>
                <form id="storeForm" onSubmit={onSubmit}>
                    <Paper elevation={2}>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={5} align="center">
                                            <Link to={{ pathname: '/dashboard', state: { page: page } }} style={{ float: "left" }}>
                                                <ArrowBackIcon sx={{ color: "#fff", verticalAlign: "text-top" }} fontSize="large"></ArrowBackIcon>
                                            </Link>
                                            <Typography component={"span"} style={{ fontWeight: 600 }}>New Store</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell style={{ minWidth: "200px" }}>Plan</TableCell>
                                        <TableCell>Phone Number</TableCell>
                                        <TableCell>Email</TableCell>
                                        <TableCell>Referred By</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <TextField variant="outlined" name="name" required
                                                onChange={(e) => setStore({...store, name: e.target.value})} />
                                        </TableCell>
                                        <TableCell>
                                            <Select variant="outlined" name="plan" required fullWidth
                                                defaultValue="Basic"
                                                onChange={(e) => setStore({ ...store, plan: e.target.value })}>
                                                <MenuItem value="Basic">Basic</MenuItem>
                                                <MenuItem value="Professional">Professional</MenuItem>
                                                <MenuItem value="Enterprise" disabled>Enterprise</MenuItem>
                                            </Select>
                                        </TableCell>
                                        <TableCell>
                                            <TextField variant="outlined" name="phoneNumber" required
                                                onChange={(e) => setStore({ ...store, phoneNumber: e.target.value })}
                                                InputProps={{
                                                    inputComponent: MaskedInput,
                                                    inputProps: {
                                                      mask: ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
                                                    },
                                                  }} />
                                        </TableCell>
                                        <TableCell>
                                            <TextField variant="outlined" name="email" required
                                                onChange={(e) => setStore({ ...store, email: e.target.value })} />
                                        </TableCell>
                                        <TableCell>
                                            <TextField variant="outlined" name="signupRef"
                                                onChange={(e) => setStore({ ...store, signupRef: e.target.value })} />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className={classes.buttonDiv}>
                            <Button
                                type="submit"
                                disabled={loading}
                                variant="contained"
                                color="primary"
                                style={{ minWidth: "200px" }}
                            >
                                Save
                                {loading && <CircularProgress size={24} />}
                            </Button>
                        </div>
                    </Paper>
                </form>
            </CardContent>
        </Card>
    );
}

export default NewStore;