import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { apiService } from "../services/ApiService";
import { toast } from "react-toastify";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Card, CardHeader, CardContent, Button } from '@material-ui/core';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  Paper,
  List,
  ListItem,
  ListItemText,
  Toolbar,
} from "@mui/material";
import { Typography, AppBar } from '@material-ui/core';
import ArrowLink from "./ArrowLink";
import ListTable from "./ListTable";

const useStyles = makeStyles({
  loaderWrapper: {
    height: "var(--fullvh)",
    display: "grid",
    justifyContent: "center",
    alignItems: "center",
  },
  card: {
    boxShadow: "1px 2px 25px 5px rgb(0 0 0 / 20%) !important",
  },
  header: {
    fontWeight: 600,
    width: "100%",
  },
  text: {
    padding: "10px",
  }
});

const StoreDetail = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [store, setStore] = useState();

  const location = useLocation();
  const history = useHistory();
  let { id } = useParams();
  const classes = useStyles();

  useEffect(() => getStore(), []);

  const getStore = () => {
    setIsLoading(true);
    apiService
      .get(`adminstore/storeReports/${id}`)
      .then(res => {
        setStore(res[0]);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        toast.error(e);
      });
  }

  const goToBellReport = () => {
    history.push({
      pathname: `/bell-report/${id}`,
      state: {
        ...location.state,
        from: location.pathname,
      }
    });
  }

  const paymentData = [
    {
      data: 'activityType',
      type: 'string',
    },
    {
      data: 'datePaid',
      type: 'date',
    },
    {
      data: 'amount',
      type: 'numeric',
      format: (data) => {
        return '$' + (data * .01)
      }
    },
    {
      data: 'cardNumber',
      type: 'string',
      format: (data) => {
        return "Payment Method: " + data;
      }
    },
  ]

  return (
    <>
      {isLoading ? (
        <div className={classes.loaderWrapper}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <Card elevation={0}>
            <CardHeader></CardHeader>
            <CardContent>
              <TableContainer component={Paper} elevation={2}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ paddingBottom: "0", paddingTop: "0" }}>
                        <ArrowLink path='/dashboard' state={{ page: location.state.page }} styles={{ color: "#fff" }} />
                      </TableCell>
                      <TableCell colSpan={4} fontSize="large" align="center">{store.name}</TableCell>
                      <TableCell><Button color="primary" variant="contained" onClick={goToBellReport}>Bell Report</Button></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>Plan</TableCell>
                      <TableCell>Phone Number</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Address</TableCell>
                      <TableCell>Pings</TableCell>
                      <TableCell>Referred By</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{store.plan.type.toUpperCase()}</TableCell>
                      <TableCell>{store.phone}</TableCell>
                      <TableCell>{store.email}</TableCell>
                      <TableCell>{store.address}</TableCell>
                      <TableCell>{store.pingsCount}</TableCell>
                      <TableCell>{store.signupRef}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
          <Card elevation={0}>
            <CardContent>
              <Paper elevation={2}>
                <AppBar position="static" elevation={0}>
                  <Toolbar variant="dense">
                    <Typography align="center" className={classes.header}>Payment History</Typography>
                  </Toolbar>
                </AppBar>
                <ListTable
                  dataURL={`adminstore/paymenthistory/${id}`}
                  columnSettings={paymentData}
                  defaultComponent={<Typography align="center" className={classes.text}>No Payment History</Typography>} />
              </Paper>
            </CardContent>
          </Card>
        </>
      )}
    </>
  );
};

export default StoreDetail;
