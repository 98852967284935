import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardHeader, CardContent, Button, Link } from "@material-ui/core";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  Paper,
  TableSortLabel,
  TablePagination,
  Box,
} from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import { apiService } from "../services/ApiService";
import { visuallyHidden } from "@mui/utils";
import DownloadIcon from '@mui/icons-material/Download';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { getComparator } from "../functions";

const useStyles = makeStyles({
  loaderWrapper: {
    height: "var(--fullvh)",
    display: "grid",
    justifyContent: "center",
    alignItems: "center",
  },
  inactive: {
    backgroundColor: "rgba(0, 0, 0, 0.04)",
  },
  tall: {
    lineHeight: "2.5 !important",
  },
  header: {
    textAlign: "center",
    paddingBottom: "0 ! important",
  },
  qrImg: {
    width: "25px"
  },
  overflowVisible: {
    overflow: "visible"
  },
  copy: {
    cursor: "pointer",
    marginLeft: "2px",
    padding: "2px",
  }
});

const columnSettings = [
  {
    id: "name",
    numeric: false,
    label: "Name",
    data: "name"
  },
  {
    id: "phone",
    numeric: false,
    label: "Phone",
    data: "user_phoneNumber"
  },
  {
    id: "email",
    numeric: false,
    label: "Email",
    data: "user_email",
  },
  {
    id: "url",
    numeric: false,
    label: "URL",
    data: "url",
  },
  {
    id: "balance",
    numeric: false,
    label: "Balance",
    data: "balance",
  },
];

const SalesRepsTable = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [loadData, setLoadData] = useState(true);
  const [data, setData] = useState(null);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const getTableData = async () => {
    setIsLoading(true);
    apiService
      .get("adminsales/salesrep")
      .then((res) => {
        setData(res);
        console.log(res)
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        toast.error(e);
      });
  };

  useEffect(() => {
    if (loadData) {
      getTableData();
      console.log(location.state);
      if (location.state && location.state.page) setPage(location.state.page);
      setLoadData(false);
    }
  }, [loadData, location.state]);

  const handleSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  const deleteSalesRep = (e, id) => {
    e.stopPropagation();
    if (window.confirm("Are you sure you want to delete?")) {
      apiService
        .delete(`adminsales/${id}`)
        .then(() => setLoadData(true))
        .catch((e) => toast.error(e));
    }
  }

  const copyToClipboard = (url, index) => {
    navigator.clipboard.writeText(url);
    var tooltip = document.getElementById("tooltiptext" + index);
    tooltip.innerHTML = "Copied!";
  }

  const tooltipTextChange = (index) => {
    var tooltip = document.getElementById("tooltiptext" + index);
    tooltip.innerHTML = "Copy to clipboard";
  }

  return (
    <>
      {isLoading ? (
        <div className={classes.loaderWrapper}>
          <CircularProgress />
        </div>
      ) : (
        <Card elevation={0}>
          <CardHeader title="Sales Representatives" className={classes.header}></CardHeader>
          <CardContent style={{ paddingBottom: "10px" }}>
            <TableContainer component={Paper} elevation={2}>
              <TableContainer sx={{ overflowY: "auto", height: "80vh" }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      {columnSettings.map((col) => (
                        <TableCell
                          key={col.id}
                          align={col.numeric ? "right" : "left"}
                          sortDirection={orderBy === col.data ? order : false}
                          colSpan={col.colSpan ?? 1}
                        >
                          <TableSortLabel
                            active={orderBy === col.data}
                            direction={orderBy === col.data ? order : "asc"}
                            onClick={(e) => handleSort(e, col.data)}
                          >
                            {col.label}
                            {orderBy === col.id ? (
                              <Box component="span" sx={visuallyHidden}>
                                {order === "desc"
                                  ? "sorted descending"
                                  : "sorted ascending"}
                              </Box>
                            ) : null}
                          </TableSortLabel>
                        </TableCell>
                      ))}
                      <TableCell>QR Code</TableCell>
                      <TableCell className={classes.headerCell}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => history.push("/new-salesrep")}
                        >
                          New
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data
                      .sort(getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        return (
                          <TableRow
                            // onClick={() => handleRowClick(row.storeId)}
                            key={index}
                          >
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.user.phoneNumber}</TableCell>
                            <TableCell>{row.user.email}</TableCell>
                            <TableCell className={classes.overflowVisible}>
                              https://curbsidebell.com?ref=
                              {row.url}
                              <div
                                onClick={e => copyToClipboard("https://curbsidebell.com?ref=" + row.url, index)}
                                onMouseOut={e => tooltipTextChange(index)}
                                className='tooltip'
                                style={{ display: "inline-block" }}
                              >
                                <span id={"tooltiptext" + index} className="tooltiptext">Copy to clipboard</span>
                                <ContentCopyIcon className={classes.copy} />
                              </div>
                            </TableCell>
                            <TableCell>{row.balance}</TableCell>
                            <TableCell>
                              <img src={row.qr} className={classes.qrImg} alt="QR Code" />
                              <Link download={row.name + "_qrcode.dib"} href={row.qr}>
                                <DownloadIcon sx={{ cursor: "pointer" }}></DownloadIcon>
                              </Link>
                            </TableCell>
                            <TableCell>
                              <Button
                                onClick={(e) =>
                                  deleteSalesRep(e, row.id)
                                }
                                color="primary"
                              >Delete</Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(event, newPage) => setPage(newPage)}
                onRowsPerPageChange={handleChangeRowsPerPage}
                showFirstButton={true}
                showLastButton={true}
              />
            </TableContainer>
          </CardContent>
        </Card>
      )}
    </>
  );
};

export default SalesRepsTable;
