import React, { useState } from 'react';
import { useLocation, useHistory, Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardHeader, CardContent, TextField, Button, Typography } from '@material-ui/core';
import CircularProgress from "@material-ui/core/CircularProgress";
import { Table, TableBody, TableCell, TableHead, TableContainer, TableRow, Paper } from "@mui/material";
import ArrowLink from "./ArrowLink";
import { apiService } from "../services/ApiService";
import { getRecaptchaToken } from "../services/RecaptchaService";
import { toast } from "react-toastify";
import { generatePassword } from '../functions';
import MaskedInput from 'react-text-mask';

const useStyles = makeStyles({
    buttonDiv: {
        textAlign: "center",
        padding: "10px",
    }
});

const NewAdmin = () => {
    const [loading, setLoading] = useState(false);
    const [admin, setAdmin] = useState();

    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const query = new URLSearchParams(location.search);
    const page = query.get("page");

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        let formData = admin;
        const exists = await apiService.post('account/user-exists', {
            email: admin.email,
            role: 'admin',
            recaptchaToken: await getRecaptchaToken()
        });

        if (exists === 'yes') {
            toast.error('Email is already registered');
            setLoading(false);
        } else {
            let endpoint = 'admin/signup';
            if (exists === 'other') {
                endpoint += '/admin-only'
            }
            else {
                formData['password'] = generatePassword(16);
            }
            formData["recaptchaToken"] = await getRecaptchaToken();
            console.log(formData)
            apiService
                .post(endpoint, formData)
                .then(res => {
                    toast.success('Admin user created successfully!');
                    history.push('/dashboard');
                })
                .then(() => setLoading(false))
                .catch(e => {
                    toast.error(e);
                    setLoading(false);
                });
        }
    }

    return (
        <Card elevation={0}>
            <CardHeader></CardHeader>
            <CardContent>
                <form id="adminForm" onSubmit={onSubmit}>
                    <Paper elevation={2}>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={5} align="center">
                                            <ArrowLink path='/dashboard' state={{ page: page }} styles={{ color: "#fff" }} />
                                            <Typography component={"span"} style={{ fontWeight: 600 }}>New Admin</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Phone Number</TableCell>
                                        <TableCell>Email</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <TextField variant="outlined" name="name" required
                                                onChange={(e) => setAdmin({ ...admin, name: e.target.value })} />
                                        </TableCell>
                                        <TableCell>
                                            <TextField variant="outlined" name="phoneNumber" required
                                                onChange={(e) => setAdmin({ ...admin, phoneNumber: e.target.value })}
                                                InputProps={{
                                                    inputComponent: MaskedInput,
                                                    inputProps: {
                                                        mask: ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
                                                    },
                                                }} />
                                        </TableCell>
                                        <TableCell>
                                            <TextField variant="outlined" name="email" required
                                                onChange={(e) => setAdmin({ ...admin, email: e.target.value })} />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className={classes.buttonDiv}>
                            <Button
                                type="submit"
                                disabled={loading}
                                variant="contained"
                                color="primary"
                                style={{ minWidth: "200px" }}
                            >
                                Save
                                {loading && <CircularProgress size={24} />}
                            </Button>
                        </div>
                    </Paper>
                </form>
            </CardContent>
        </Card>
    );
}

export default NewAdmin;