import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import { apiService } from '../services/ApiService';

const styles = {
	root: {
		height: '100%',
	},
	logo: {
		height: '100px',
		margin: '0 auto 20px auto',
	},
	form: {
		height: '100%',
		alignContent: 'center',
		display: 'grid',
		rowGap: '30px',
		margin: '0 auto',
		maxWidth: '400px',
	},
	loaderWrapper: {
		height: 'var(--fullvh)',
		display: 'grid',
		justifyContent: 'center',
		alignItems: 'center',
	},
};

class GetResetPasswordEmail extends React.Component {
	state = {
		isLoading: false,
		emailSent: false,
	};

	onSubmit = async (e) => {
		try {
			e.preventDefault();
			this.setState({ isLoading: true });
			let loginInfo = this.formToObject(e.target);
			loginInfo.recaptchaToken = await this.getRecaptchaToken();
			loginInfo.URL = process.env.REACT_APP_ADMIN_URL;
			console.log(loginInfo);
			await apiService.post(`account/send-reset-password-email`, loginInfo);
			this.setState({ isLoading: false, emailSent: true });
		} catch (err) {
			this.setState({ isLoading: false });
			console.error(err);
			toast.error(err);
		}
	};

	formToObject(form) {
		return Object.fromEntries(new FormData(form));
	}

	async getRecaptchaToken() {
		return new Promise((resolve, reject) => {
			window.grecaptcha.ready(() => {
				window.grecaptcha
					.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY)
					.then(resolve);
			});
		});
	}

	renderForm(classes) {
		return (
			<>
				<style>{`
					.grecaptcha-badge {
						visibility: visible;
					}
				`}</style>
				<form className={classes.form} onSubmit={this.onSubmit}>
					<img className={classes.logo} src="/curbsideBell-logo.png" alt="" />
					<TextField
						name="email"
						required
						label="Email"
						variant="outlined"
						type="email"
					/>
					<Button type="submit" variant="contained" color="primary">
						Get Reset Password Email
					</Button>
				</form>
			</>
		);
	}

	renderLoader(classes) {
		return (
			<div className={classes.loaderWrapper}>
				<CircularProgress />
			</div>
		);
	}

	renderEmailSent(classes) {
		return (
			<div className={classes.loaderWrapper}>
				Check your email inbox for a reset password link.
			</div>
		);
	}

	render() {
		const classes = this.props.classes;
		return (
			<div className={classes.root}>
				{this.state.isLoading
					? this.renderLoader(classes)
					: this.state.emailSent
					? this.renderEmailSent(classes)
					: this.renderForm(classes)}
			</div>
		);
	}
}

export default withStyles(styles)(GetResetPasswordEmail);
