import React, { useState, useEffect, createContext } from 'react';
import { apiService } from '../services/ApiService';

export const AdminContext = createContext();

const AdminState = ({ children }) => {
	const [user, setUser] = useState(null);

	useEffect(() => {
		setUser(getUserFromSession());
	}, []);

	const login = async (loginInfo) => {
		let user = await apiService
			.post(`account/login`, loginInfo);
		console.log(user);
		return new Promise((resolve, reject) => {
			if (user.role.includes('Admin')) {
				setUser(user);
				sessionStorage.setItem('user', user);
				resolve();
			}
			else
				reject('Unauthorized');
		});
	}

	const logout = async () => {		
		await apiService.post('account/logout');
		return new Promise((resolve, reject) => {
			setUser(null);
			sessionStorage.removeItem('user');
			resolve();
		});
	};

	const getUserFromSession = () => {
		const user = sessionStorage.getItem('user');
		return user ?? null;
	};

	return (
		<AdminContext.Provider
			value={{
				user,
				getUserFromSession,
				login,
				logout
			}}
		>
			{children}
		</AdminContext.Provider>
	);
};

export default AdminState;
