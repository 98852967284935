import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { apiService } from "../services/ApiService";
import { toast } from "react-toastify";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
    Paper,
    List,
    ListItem,
    ListItemText,
    Toolbar,
} from "@mui/material";

const useStyles = makeStyles({
    loaderWrapper: {
        height: "var(--fullvh)",
        display: "grid",
        justifyContent: "center",
        alignItems: "center",
    },
});

const ListTable = ({ columnSettings, dataURL, defaultComponent }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);

    const classes = useStyles();

    const getTableData = () => {
        setIsLoading(true);
        apiService
            .get(dataURL)
            .then((res) => {
                setData(res);
                setIsLoading(false);
            })
            .catch((e) => {
                setIsLoading(false);
                toast.error(e);
            });
    };

    useEffect(() => getTableData(), []);

    function formatData(data, type, format) {
        if (format)
            return format(data);

        switch (type) {
            case 'date':
                if (data === null)
                    return;
                else
                    return new Intl.DateTimeFormat('en-US', {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric'
                    }).format(new Date(data));
            case 'boolean':
                return data.toString();
            default:
                return data;
        }
    }

    return (isLoading ?
        <div className={classes.loaderWrapper}>
            <CircularProgress />
        </div>
        : data.length === 0 && defaultComponent ?
            { ...defaultComponent }
            : <List>
                {data.map((obj, index) => {
                    console.log(obj)
                    return <ListItem key={index}>
                        {columnSettings.map((col) => {
                            return <ListItemText key={col.data + index} primary={formatData(obj[col.data], col.type, col.format)} />
                        })}
                    </ListItem>;
                })}
            </List>
    );
}

export default ListTable;