function generatePassword(len) {
    const strings = "abscefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890";      
    let str = "";      
    do {      
        str = "";      
        for (let i = 0; i < len; i++) {      
        const rand = Math.floor(Math.random() * strings.length);      
        str += strings[rand];      
        }      
    } while (!/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*/.test(str));
    return str;      
}

//via Stack Overflow: https://stackoverflow.com/a/56253298
function flattenObj(obj, parent, res = {}) {
    for (let key in obj) {
        let propName = parent ? parent + '_' + key : key;
        if (typeof obj[key] == 'object') {
            flattenObj(obj[key], propName, res);
        } else {
            res[propName] = obj[key];
        }
    }
    return res;
};

function descendingComparator(a, b, orderBy) {
    a = flattenObj(a);
    b = flattenObj(b);
    if (typeof a[orderBy] === 'string' && typeof b[orderBy] === 'string')  {
      return b[orderBy].localeCompare(a[orderBy]);
    }

    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

export { generatePassword, flattenObj, getComparator };