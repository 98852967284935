import React from 'react';
import {
	createTheme,
	makeStyles,
	ThemeProvider,
} from '@material-ui/core/styles';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from 'react-router-dom';
import Login from './components/Login';
import StoreTable from './components/StoreTable';
import StoreDetail from './components/StoreDetail';
import NewStore from './components/NewStore';
import SalesRepsTable from './components/SalesReps';
import NewSalesRep from './components/NewSalesRep';
import Toolbar from './components/Toolbar';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AdminState from './context/AdminContext';
import GetResetPasswordEmail from './components/GetResetPasswordEmail';
import ResetPasswordWithToken from './components/ResetPasswordWithToken';
import BellReport from './components/BellReport';
import NewAdmin from './components/NewAdmin';

let fullvh = window.innerHeight;
document.documentElement.style.setProperty('--vh', `${fullvh * 0.01}px`);
document.documentElement.style.setProperty('--fullvh', `${fullvh}px`);

const useStyles = makeStyles((theme) => ({
	root: {
		// height: 'var(--fullvh)',
	},
	footer: {
		padding: '6px',
		textAlign: 'center',
	},
	link: {
		color: theme.palette.primary.main,
	},
}));

const theme = createTheme({
	palette: {
		primary: {
			light: '#9bbaa2',
			main: '#82A98B',
			dark: '#5b7661',
			contrastText: '#fff',
		},
		secondary: {
			light: '#ffffff',
			main: '#d6ecda',
			dark: '#a5baa8',
			contrastText: '#000',
		},
	},
	typography: {
		subtitle1: {
			fontSize: '1rem',
		},
		subtitle2: {
			fontSize: '0.75rem',
		},
	},
});

function App() {
	const classes = useStyles();
	return (
		<AdminState>
			<ThemeProvider theme={theme}>
				<div className={classes.root}>
					<Router>
						<Toolbar>
							<Switch>
								<Route exact path="/" render={() => <Redirect to="/login" />} />
								<Route path="/login" component={Login} />
								<Route path="/dashboard" component={StoreTable} />
								<Route
									path="/get-reset-password-email"
									component={GetResetPasswordEmail}
								/>
								{/* <Route path="/verify-email" component={VerifyEmail} /> */}
								<Route
									path="/reset-password-with-token"
									component={ResetPasswordWithToken}
								/>
								<Route path="/store/:id">
									<StoreDetail />
								</Route>
								<Route path="/new-store">
									<NewStore />
								</Route>
								<Route path="/salesreps">
									<SalesRepsTable />
								</Route>
								<Route path="/new-salesrep">
									<NewSalesRep />
								</Route>
								<Route path="/bell-report/:id">
									<BellReport />
								</Route>
								<Route path="/new-admin">
									<NewAdmin />
								</Route>
							</Switch>
						</Toolbar>
					</Router>
					<ToastContainer />
				</div>
			</ThemeProvider>
		</AdminState>
	);
}

export default App;
